import React from "react"
import alimentacionEmbarazo from '../../assets/infografias/infografia_alimentacion_embarazo.jpg'
import controlMedico from "../../assets/infografias/infografia_control_medico_embarazo.jpg"
import infografiaNutricion from "../../assets/infografias/infografia_nutricion_diabetes.jpg"
import nutricionHipertension from "../../assets/infografias/infografia_nutricion_hipertension.jpg"
import despuesParto from "../../assets/infografias/infografia_recomendaciones_despues_del_parto.jpg"
import protegerBebe from "../../assets/infografias/infografia_protegger_bebe.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

const Manuales = () => {
  return (
  <section id = "telesan-hero2">
    <div className = "content-telesan" id="productos">
        <h1 id="manuales">Productos y Manuales</h1>
            <div className = "row">
                <div class="column">
                    <ul>
                        <li>
                          <FontAwesomeIcon icon={faDownload} />
                          <a href = {alimentacionEmbarazo} download target="_blank" rel="noreferrer"> Descargar Alimentación en el embarazo
                          </a></li>
                        <li>
                          <FontAwesomeIcon icon={faDownload} />
                          <a href = {controlMedico} download target="_blank" rel ="noreferrer"> Descargar Control médico durante el embarazo</a></li>
                        <li>
                          <FontAwesomeIcon icon={faDownload} />
                          <a href = {infografiaNutricion} download target="_blank" rel ="noreferrer"> Descargar Nutrición del paciente con diabetes mielitus</a></li>
                        <li>
                          <FontAwesomeIcon icon={faDownload} />
                          <a href = {nutricionHipertension} download target="_blank" rel ="noreferrer"> Descargar Nutrición paciente con hipertensión</a></li>
                        <li>
                          <FontAwesomeIcon icon={faDownload} />
                          <a href = {despuesParto} download target="_blank" rel ="noreferrer"> Descargar Recomendaciones despues del parto</a></li>
                        <li>
                        <FontAwesomeIcon icon={faDownload} />
                          <a href = {protegerBebe} download target="_blank" rel = "noreferrer"> Descargar Beneficios de la lactancia materna</a>
                          </li>
                    </ul>
                </div> 
            </div>     

    </div>
  </section>
  )
}


export default Manuales