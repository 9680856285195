import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons'
const TeleEducacion = () => {
    return (
    <section id = "telesan-hero2">
        <div className = "content-telesan">
            <h1 id="tele-educacion">
                <FontAwesomeIcon icon={faChalkboardTeacher} />   
                TeleAsistencia     
            </h1>
            <h2>EduAcademia TeleSAN</h2>
            <div className = "row"> 
                <div className = "video-container">
                    <iframe  src="https://www.youtube.com/embed/videoseries?list=PLmKftbz_4YHeTXMXBnOrdoFNB9Rx3tQyd" 
                    title="YouTube video player" 
                    frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen>
                    </iframe>
                </div>   
            </div>
        </div>
        <div className = "content-telesan">
            <div className = "row">
                <div className="column">
                        <h2>EduPaciente Misquito TeleSAN</h2>
                        <div className = "video-container">
                            <iframe width="auto" src="https://www.youtube.com/embed/videoseries?list=PLmKftbz_4YHdwT0_59TKrs3qepJxC4Frv" 
                            title="YouTube video player" 
                            frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen>
                            </iframe>
                        </div>
                </div>
                <div className="column">
                    <h2>EduPaciente Español TeleSAN</h2>
                    <div className = "video-container">
                        <iframe width="auto" height="auto" src="https://www.youtube.com/embed/videoseries?list=PLmKftbz_4YHcYazpbGrbqJsRdlgFcJnY8" 
                        title="YouTube video player" 
                        frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen>
                        </iframe>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    )
}

export default TeleEducacion;