import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import Hero from "../components/servicios/Hero"
import Participantes from "../components/servicios/ServiciosParticipantes"
import TeleEducacion from "../components/servicios/TeleEducacion"
import Diagnosticos from "../components/servicios/Diagnosticos";
import Manuales from "../components/servicios/Manuales"

const IndexPage = (props) => (
  <Layout>
    <Seo title="Servicios" />
    <Hero/>
    <Participantes/>
    <TeleEducacion/>
    <Diagnosticos/>
    <Manuales/>
  </Layout>
);

export default IndexPage;

