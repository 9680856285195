import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStethoscope } from '@fortawesome/free-solid-svg-icons'

const Diagnosticos = () => {
    return (
    <section id = "telesan-hero1">
        <div className = "telesan-row">
            <div className = "columns grafico">
                <StaticImage
                    src = "../../assets/servicios/servicios_diagnosticos.jpg"
                    width = {700}
                    alt= "Telesan teleconsulta"
                />]
            </div>
            <div className = "texto">
                <h1>
                <FontAwesomeIcon icon={faStethoscope} />
                Diagnósticos</h1>
                <p>
                    <ul>
                        <li>Dianósticos de infraestructura de establecimientos.</li>
                        <li>Conocimientos, aptitudes y prácticas en <em>TeleSalud.</em> </li>
                        <li>Investigación cualitativa de percepción de <em>TeleSalud.</em></li>
                        <li>Diagnósticos de conectividad. </li>
                        <li>Costeos en <em>TeleSalud</em> aplicadas al país.</li>                        
                    </ul>
                </p>
            </div>
        </div>
       
    </section>
    )
}

export default Diagnosticos