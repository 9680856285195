import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

const Postgrados = () => {
    const classes = useStyles();
    return (
        <div id = "postgrados">
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.heading}>Especialidades/Postgrados (11)</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    <ul>
                        <li>Medicina interna</li>
                        <li>Pediatría</li>
                        <li>Ginecoobstetricia</li>
                        <li>Cirugía General</li>
                        <li>Psiquiatría</li>
                        <li>Medicina de Rehabilitación (TeleEducación)</li>
                        <li>Neurología</li>
                        <li>Oftalmología</li>
                        <li>Otorrinolaringología (TeleEducación)</li>
                        <li>Dermatología</li>
                        <li>Ortopedia</li>
                    </ul>
                </Typography>
            </AccordionDetails>
        </Accordion>
      </div>
    )
}

export default Postgrados;