import React from "react"
import Postgrados from "./Participantes/Postgrados"
import SubEspecialidades from "./Participantes/SubEspecialidades"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFirstAid } from '@fortawesome/free-solid-svg-icons'
const ServiciosParticipantes = () => {
    return (
    <section id = "telesan-hero1">
        <div className = "content-telesan">
            
            <h1 id="servicios-medicos">
                <FontAwesomeIcon icon={faFirstAid} />
                Servicios Médicos
            </h1>
            <h2>Servicios participantes en la red (24)</h2>
            <div className= "row">
                <div className = "columns">
                   <Postgrados/>
                </div>
                <div className = "column">
                   <SubEspecialidades/>
                 </div>
            </div>
        </div>
    </section>
    )
}

export default ServiciosParticipantes;